header {
	background-color: #25165a;
	position: sticky;
	top: 0;
	z-index: 999;

	nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 0;

		width: 95%;
		max-width: 1300px;
		margin: auto;

		img {
			width: 150px;
		}

		.nav__links {
			display: flex;
			gap: 25px;
			align-items: center;

			@media (max-width: 1000px) {
				background-color: white;
				width: 100%;
				position: absolute;

				top: -233px;
				left: 0;

				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 20px 0;
			}

			li {
				list-style-type: none;

				@media (max-width: 1000px) {
					width: 95%;
					margin: auto;
				}

				a {
					color: white;

					@media (max-width: 1000px) {
						color: black;
					}
				}

				button {
					font-size: 15px;
					font-weight: 500;
					color: white;
					background-color: #6029a3;

					&:hover {
						background-color: #6029a3f1;
					}

					border: none;
					padding: 10px 20px;
					cursor: pointer;
				}
			}
		}

		.nav__links--active {
			top: 90.55px;
		}

		.mobile__menu {
			display: none;

			@media (max-width: 1000px) {
				display: block;
			}

			svg {
				font-size: 30px;
				color: white;
				cursor: pointer;
			}

			.menu__none {
				display: none;
			}
		}
	}
}
