.virtual__assistants__images {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 25px;

	width: 95%;
	max-width: 1300px;
	margin: 50px auto;

	li {
		list-style-type: none;

		img {
			width: 100%;
		}

		&:nth-of-type(2) {
			img {
				margin-top: 25px;
			}
		}
	}
}

.faqs__va {
	display: grid;
	grid-template-rows: 1fr;
	grid-gap: 15px;

	width: 95%;
	max-width: 1300px;
	margin: 50px auto;

	li {
		color: white;
		background-color: #25165a;
		list-style-type: none;
		padding: 30px;

		&:nth-of-type(1) {
			ul {
				margin: 10px 0;

				li {
					line-height: 1.5;
					list-style-type: disc;
					list-style-position: inside;
					padding: 0;
				}
			}
		}

		.faqs__va__question {
			color: white;
			font-size: 20px;
			margin-bottom: 15px;
		}

		.faqs__va__answer {
			color: white;
			line-height: 1.5;
		}
	}
}
