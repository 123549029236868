@import url('https://fonts.googleapis.com/css2?family=Anton&family=Poppins:ital,wght@0,400;0,500;1,400;1,500&display=swap');

* {
	font-family: 'Poppins', sans-serif;
	text-decoration: none;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	outline: none;
	scroll-behavior: smooth;
}
