.technical__expert__images {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 25px;

	width: 95%;
	max-width: 1300px;
	margin: 50px auto;

	li {
		list-style-type: none;

		img {
			width: 100%;
		}

		&:nth-of-type(2) {
			img {
				margin-top: 25px;
			}
		}
	}
}
