.section__header {
	width: 95%;
	max-width: 1300px;
	margin: 50px auto;

	&__title {
		font-size: 25px;
		margin-bottom: 10px;
	}

	&__subtitle {
		font-size: 18px;
		font-weight: 500;
		line-height: 1.5;
		max-width: 1000px;
	}
}
