footer {
	background-color: #25165a;
	padding: 30px 0;

	.footer__content {
		text-align: center;
		width: 95%;
		max-width: 1300px;
		margin: auto;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 900px) {
			flex-direction: column;
			gap: 30px;
		}

		ul {
			display: flex;
			align-items: center;
			gap: 15px;

			@media (max-width: 600px) {
				flex-direction: column;
				gap: 30px;
			}

			li {
				list-style: none;

				a {
					color: white;
				}

				button {
					font-size: 15px;
					font-weight: 500;
					color: white;
					background-color: #6029a3;

					&:hover {
						background-color: #6029a3f1;
					}

					border: none;
					padding: 10px 15px;
					cursor: pointer;
				}
			}
		}
	}
}
