.home {
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	background-image: url(../../Images/home-bg.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-top: -40px;

	height: 80vh;

	@media (max-width: 350px) {
		height: 90vh;
	}

	&__overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #25165ab9;
	}

	&__content {
		text-align: center;
		color: white;
		position: absolute;

		h1 {
			font-family: 'Anton', sans-serif;
			font-size: 70px;
			margin: 25px 0 25px;

			@media (max-width: 1200px) {
				font-size: 60px;
			}

			@media (max-width: 800px) {
				font-size: 50px;
			}

			@media (max-width: 500px) {
				font-size: 40px;
			}
		}

		p {
			font-size: 18px;

			@media (max-width: 1200px) {
				font-size: 17px;
			}

			line-height: 1.8;
			width: 95%;
			max-width: 900px;
			margin: 0 auto;

			&:nth-of-type(1) {
				font-size: 15px;
				font-weight: 500;

				background-color: #6029a3d8;
				width: max-content;
				padding: 10px 25px;
			}

			&:nth-of-type(2) {
				margin: 0 auto 25px;
			}
		}

		ul {
			display: flex;
			justify-content: center;
			gap: 25px;
			align-items: center;

			@media (max-width: 700px) {
				flex-direction: column;
				gap: 15px;
			}

			li {
				list-style-type: none;

				button {
					font-size: 15px;
					font-weight: 500;
					color: white;
					background-color: #6029a3;

					&:hover {
						background-color: #6029a3f1;
					}

					border: none;
					padding: 15px 30px;
					cursor: pointer;
				}

				a {
					font-size: 15px;
					color: white;
					font-weight: 500;
					text-decoration: underline;
				}
			}
		}
	}
}

.about {
	display: flex;
	justify-content: center;
	align-items: center;

	background-image: url(../../Images/cebu-ayala.webp);
	background-position: center;
	background-size: cover;

	&__content {
		text-align: center;
		background-color: rgba(255, 255, 255, 0.8);
		width: 95%;
		max-width: 800px;
		padding: 50px;
		margin: 150px 0;

		@media (max-width: 800px) {
			padding: 30px;
		}

		h2 {
			font-size: 25px;
			margin-bottom: 15px;

			@media (max-width: 500px) {
				font-size: 20px;
			}
		}

		p {
			line-height: 1.8;
		}
	}
}

.founder {
	background-color: #25165a;

	&__container {
		display: grid;
		grid-template-columns: 2fr 1fr;
		align-items: center;
		grid-gap: 100px;

		@media (max-width: 1150px) {
			grid-template-columns: 1fr;
			grid-gap: 50px;
		}

		width: 95%;
		max-width: 1300px;
		padding: 50px 0;
		margin: auto;
	}

	&__details {
		color: white;

		h2 {
			margin-bottom: 15px;
		}

		p {
			line-height: 1.8;
		}
	}

	&__profile {
		display: flex;
		justify-content: center;

		img {
			width: 90%;
			max-width: 400px;
		}
	}
}

.mv {
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@media (max-width: 1100px) {
		grid-template-columns: 600px;
		justify-content: center;
	}

	@media (max-width: 650px) {
		grid-template-columns: 1fr;
	}

	grid-gap: 50px;

	width: 95%;
	max-width: 1300px;
	margin: 50px auto;

	li {
		color: white;
		background-color: #25165a;
		list-style: none;
		padding: 30px;

		h3 {
			font-size: 25px;
			font-weight: 500;
			margin-bottom: 10px;

			@media (max-width: 500px) {
				font-size: 20px;
			}
		}

		p {
			line-height: 1.8;
		}
	}
}
