.faqs {
	display: grid;
	grid-template-rows: 1fr;
	grid-gap: 15px;

	width: 95%;
	max-width: 1300px;
	margin: 50px auto;

	li {
		background-color: #25165a;
		list-style-type: none;
		padding: 30px;

		.faqs__question {
			color: white;
			font-size: 20px;
			margin-bottom: 15px;
		}

		.faqs__answer {
			color: white;
			line-height: 1.5;
		}
	}
}
